<script>
export default {
  name: "CardThird",
};
</script>
<template>
  <div class="card">
    <img src="//unsplash.it/500/500" alt="" />
    <h3>Yoga Exercise</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. At a nibh
      volutpat luctus adipiscing
    </p>
  </div>
</template>
<style scoped>
.card {
  margin: 1rem;
  width: 200px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.card h3 {
  color: #78b02b;
}
.card img {
  width: 75px;
  height: 75px;
  margin-bottom: 1rem;
  border-radius: 50%;
}
.card p {
  margin-top: 1rem;
  text-align: center;
  line-height: 146.52%;
}
</style>
