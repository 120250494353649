<template>
  <nav aria-label="navigation">
    <div class="logo"><img src="../../public/Logo.png" alt="Logo" /></div>
    <span class="menu" @click="menuClick">
      <span></span>
      <span></span>
      <span></span>
    </span>
    <ul class="ul" ref="menuUl" @click="menuClick">
      <router-link to="/">
        <li>Home</li>
      </router-link>
      <router-link to="/about">
        <li>About</li>
      </router-link>
      <router-link to="/readSamithas">
        <li>Read Samhitas</li>
      </router-link>
      <router-link to="/services">
        <li>Services</li>
      </router-link>
      <router-link to="/consult">
        <li>Consult Us</li>
      </router-link>
      <router-link to="/product">
        <li>Product</li>
      </router-link>
      <router-link to="/userManuals">
        <li>User Manuals</li>
      </router-link>
      <router-link to="/blogs">
        <li>Blogs</li>
      </router-link>
    </ul>
  </nav>
</template>
<style scoped>
nav {
  width: 100vw;
  height: 10vh;
  background-color: #78b02b;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: sans-serif;
}
nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
}
nav a.router-link-exact-active {
  color: #78b02b;
  background-color: white;
}
nav ul li {
  padding: 1rem 1.5rem;
  transition: all 0.2s ease-in-out;
}
nav ul li:hover {
  color: #78b02b;
  background-color: white;
  cursor: pointer;
}
.show {
  transform: translateX(0);
}
.menu {
  display: none;
}
.logo {
  display: none;
}
a {
  text-decoration: none;
  color: white;
}
@media screen and (max-width: 920px) {
  body {
    overflow-x: hidden;
  }
  nav {
    flex-direction: column;
    height: 10vh;
    background-color: white;
    overflow-x: visible;
    position: relative;
  }
  nav ul {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    height: 100vh;
    margin-top: 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transform: translateX(-100%);
    flex-direction: column;
    background-color: none;
    transition: all 0.5s ease-in-out;
    background-color: #78b02b99;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 5;
  }
  nav ul li {
    width: 100vw;
    height: 50px;
  }
  .menu {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: inline;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 30px;
    height: 30px;
    z-index: 3;
  }
  .menu > span {
    margin: 2px 0;
    display: inline-block;
    width: 100%;
    height: 5px;
    background-color: #78b02b;
  }
  .logo {
    position: absolute;
    top: -1.5rem;
    left: -1rem;
    width: 100px;
    height: 100px;
  }
  .logo img {
    height: 100%;
    object-fit: fill;
    border-radius: 100%;
  }
}
</style>
<script>
export default {
  name: "NavBar",
  methods: {
    menuClick() {
      const ul = this.$refs.menuUl;
      ul.classList.toggle("show");
    },
  },
};
</script>
