<template>
  <div class="home">
    <Carousel />
    <h1>Try Our Products 🍀</h1>
    <section class="ScrollSection">
      <Card1
        v-for="card in cardData"
        :key="card"
        :heading="card.heading"
        :location="card.location"
        :price="card.price"
        :img="card.img"
      />
    </section>
    <h1>Experiencing a fulfiling life 🧘‍♂️</h1>
    <section class="StaticSection">
      <Card3 />
      <Card3 />
      <Card3 />
    </section>
    <h1>Read Samhitas 📗</h1>
    <section class="ScrollSection">
      <Card2
        v-for="card in cardData2"
        :key="card"
        :heading="card.heading"
        :info="card.info"
        :img="card.img"
      />
    </section>
  </div>
</template>

<script>
import Card1 from "@/components/Card1.vue";
import Card2 from "@/components/Card2.vue";
import Carousel from "@/components/Carousel.vue";
import Card3 from "@/components/Card3.vue";
export default {
  name: "HomeView",
  components: { Card1, Carousel, Card2, Card3 },
  data() {
    return {
      cardData: [
        {
          heading: "Test1",
          location: "New Delhi,India",
          price: "159",
          img: "//unsplash.it/500/500",
        },
        {
          heading: "Test1",
          location: "New Delhi,India",
          price: "159",
          img: "//unsplash.it/500/500",
        },
        {
          heading: "Test1",
          location: "New Delhi,India",
          price: "159",
          img: "//unsplash.it/500/500",
        },
        {
          heading: "Test1",
          location: "New Delhi,India",
          price: "159",
          img: "//unsplash.it/500/500",
        },
        {
          heading: "Test1",
          location: "New Delhi,India",
          price: "159",
          img: "//unsplash.it/500/500",
        },
        {
          heading: "Test1",
          location: "New Delhi,India",
          price: "159",
          img: "//unsplash.it/500/500",
        },
      ],
      cardData2: [
        {
          heading: "Samhita,Sanskrit and Siddhant",
          info: "This is the first and the most important Department of Ayurveda, Also known as Basic principles. This department is concerned with basic principles of Ayurveda.",
          img: "//unsplash.it/500/500",
        },
        {
          heading: "Samhita,Sanskrit and Siddhant",
          info: "This is the first and the most important Department of Ayurveda, Also known as Basic principles. This department is concerned with basic principles of Ayurveda.",
          img: "//unsplash.it/500/500",
        },
        {
          heading: "Samhita,Sanskrit and Siddhant",
          info: "This is the first and the most important Department of Ayurveda, Also known as Basic principles. This department is concerned with basic principles of Ayurveda.",
          img: "//unsplash.it/500/500",
        },
        {
          heading: "Samhita,Sanskrit and Siddhant",
          info: "This is the first and the most important Department of Ayurveda, Also known as Basic principles. This department is concerned with basic principles of Ayurveda.",
          img: "//unsplash.it/500/500",
        },
        {
          heading: "Samhita,Sanskrit and Siddhant",
          info: "This is the first and the most important Department of Ayurveda, Also known as Basic principles. This department is concerned with basic principles of Ayurveda.",
          img: "//unsplash.it/500/500",
        },
        {
          heading: "Samhita,Sanskrit and Siddhant",
          info: "This is the first and the most important Department of Ayurveda, Also known as Basic principles. This department is concerned with basic principles of Ayurveda.",
          img: "//unsplash.it/500/500",
        },
      ],
    };
  },
};
</script>

<style scoped>
h1 {
  margin: 10rem 0 3rem 2rem;
  font-family: sans-serif;
}
@media screen and (max-width: 800px) {
  h1 {
    font-size: 1.3rem;
  }
  .StaticSection {
    flex-direction: column;
  }
}
.ScrollSection {
  width: 90vw;
  margin: auto;
  height: max-content;
  padding: 2rem;
  padding-top: 0.5rem;
  overflow-x: scroll;
  display: grid;
  grid-auto-flow: column;
  gap: 3rem;
  overscroll-behavior-inline: contain;
  scroll-snap-type: x mandatory;
  scroll-padding-inline: 1rem;
  font-family: sans-serif;
}
.StaticSection {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80vw;
  margin: auto;
  padding: 2rem;
}
</style>
