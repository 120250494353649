<script>
export default {
  name: "CardSecond",
  props: {
    heading: String,
    info: String,
    img: String,
  },
};
</script>
<template>
  <div class="card">
    <div class="image">
      <img :src="img" alt="" />
    </div>
    <div class="content">
      <h2>{{ heading }}</h2>
      <h2></h2>
      <p>{{ info }} <span>Read More</span></p>
    </div>
  </div>
</template>
<style scoped>
.card {
  font-family: sans-serif;
  scroll-snap-align: start;
  width: 289px;
  height: 362px;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}
.card .image {
  width: 249px;
  height: 129px;
  margin: 22px auto 19px auto;
}
.card .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.card .content {
  width: 85%;
  margin: auto;
  line-height: 157.52%;
  padding-bottom: 0.5rem;
}
.card .content h2 {
  font-size: 16px;
}
.card .content p {
  margin-top: 10px;
  font-size: 12px;
}
.card .content p span {
  color: #78b02b;
}
.card:hover {
  transform: scale(1);
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.6);
}
</style>
