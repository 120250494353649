<template v-slot:container-start>
  <div class="container">
    <swiper
      :slides-per-view="2"
      :space-between="50"
      :centeredSlides="true"
      :loop="true"
      :autoplay="{
        delay: 10000,
        disableOnInteraction: false,
      }"
      :pagination="{ clickable: true }"
      :modules="modules"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      :breakpoints="{
        0: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1100: {
          slidesPerView: 2,
        },
      }"
    >
      <swiper-slide v-slot="{ isActive }">
        <div :class="{ disp: isActive, card: !isActive }">
          <h1 class="item disp">
            <img
              src="https://apod.nasa.gov/apod/image/0209/pillars_tape_big.jpg"
              alt=""
              srcset=""
            />
          </h1>
        </div>
      </swiper-slide>

      <swiper-slide v-slot="{ isActive }">
        <div :class="{ disp: isActive, card: !isActive }">
          <h1 class="item disp">
            <img
              src="https://apod.nasa.gov/apod/image/0309/marshorizon_stronge_big.jpg"
              alt=""
              srcset=""
            />
          </h1>
        </div>
      </swiper-slide>

      <swiper-slide v-slot="{ isActive }">
        <div :class="{ disp: isActive, card: !isActive }">
          <h1 class="item disp">
            <img
              src="https://apod.nasa.gov/apod/image/0409/lmc_tan_full.jpg"
              alt=""
              srcset=""
            />
          </h1>
        </div>
      </swiper-slide>

      <swiper-slide v-slot="{ isActive }">
        <div :class="{ disp: isActive, card: !isActive }">
          <h1 class="item disp">
            <img
              src="https://apod.nasa.gov/apod/image/0509/trumpler14_cxc_f.jpg"
              alt=""
              srcset=""
            />
          </h1>
        </div>
      </swiper-slide>

      <swiper-slide v-slot="{ isActive }">
        <div :class="{ disp: isActive, card: !isActive }">
          <h1 class="item disp">
            <img
              src="https://apod.nasa.gov/apod/image/0609/m66_eso_f.jpg"
              alt=""
              srcset=""
            />
          </h1>
        </div>
      </swiper-slide>

      <swiper-slide v-slot="{ isActive }">
        <div :class="{ disp: isActive, card: !isActive }">
          <h1 class="item disp">
            <img
              src="https://apod.nasa.gov/apod/image/0709/lunation_ajc.gif"
              alt=""
              srcset=""
            />
          </h1>
        </div>
      </swiper-slide>

      ...
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components

import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper";

export default {
  name: "CarouselComponent",
  data() {
    return {
      isActive: true,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };

    return {
      onSwiper,
      onSlideChange,
      modules: [Autoplay, Navigation],
    };
  },
};
</script>

<style scoped>
body {
  display: flex;
  overflow-x: hidden;
  background-color: #000;
  justify-content: center;
  text-align: center;
  padding: 0%;
  margin: 0%;
}

.container {
  display: flex;
  overflow-x: hidden;
  /* background-color:#FDEFDA; */
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 80vh;
  margin: 0;
  background-repeat: no-repeat;
}

.carousel {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 8px;
}

.item {
  margin: auto;
  width: 30vw;
  height: 30vh;
  background-color: aquamarine;
  border-radius: 20px;
}

/* Card Main Styling */
.disp {
  z-index: 3000;
  width: 50vw;
  height: 50vh;
  transition-timing-function: ease-out;
}

.card {
  transition-timing-function: ease-in;
  opacity: 0.5;
  z-index: 1000;
  width: 50vw;
  height: 50vh;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
}

img {
  border-radius: 20px;
  width: inherit;
  height: inherit;
  object-fit: cover;
}

@media (max-width: 1100px) {
  .item {
    margin: auto;
    width: 60vw;
    height: 40vh;
    background-color: aquamarine;
  }
}

@media (max-width: 600px) {
  .container {
    align-items: flex-start;
    height: auto;
  }

  .item {
    margin: auto;
    width: 40vw;
    height: 40vh;
    background-color: aquamarine;
    border-radius: 20px;
  }

  /* Card Main Styling */
  .disp {
    z-index: 3000;
    width: 60vw;
    height: 15vh;
    transition-timing-function: ease-out;
  }

  .card {
    transition-timing-function: ease-in;
    opacity: 0.5;
    z-index: 1000;
    width: 60vw;
    height: 15vh;
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    transform: scale(0.7);
  }

  img {
    border-radius: 20px;
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
}
</style>
