<script>
export default {
  name: "CardFirst",
  props: {
    heading: String,
    location: String,
    price: String,
    img: String,
  },
};
</script>


<template>
  <div class="card">
    <div class="image">
      <img :src="img" alt="" />
    </div>
    <div class="content">
      <h2>{{ heading }}</h2>
      <h2></h2>
      <p><i class="bx bx-map"></i>{{ this.location }}</p>
    </div>
    <div class="price">₹{{ this.price }}</div>
  </div>
</template>

<style scoped>

.card {
  font-family: sans-serif;
  scroll-snap-align: start;
  width: 259px;
  height: 319px;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.03);
}
.card .image {
  width: 200px;
  height: 125px;
  margin: 23px auto 17px auto;
}
.card .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.card .content {
  width: 80%;
  margin: auto;
}
.card .content h2 {
  font-size: 16px;
}
.card .content p {
  margin-top: 10px;
  font-size: 12px;
}
.card .price {
  width: 80%;
  margin: 16px auto;
  font-size: 14px;
}
.card:hover {
  transform: scale(1);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
}
</style>
