<script>
export default {
  name: "FooterElement",
};
</script>
<template>
  <footer>
    <div class="top">
      <div>
        <h3>ABOUT US</h3>
        <ul>
          <li>Lorem ipsum dolor sit amet.</li>
          <li>Necessitatibus, magni. Velit, nobis! Asperiores.</li>
          <li>Consequuntur odit odio alias sint.</li>
          <li>Veniam in dignissimos maxime sed.</li>
          <li>Nemo minima obcaecati fuga reprehenderit.</li>
        </ul>
      </div>
      <div>
        <h3>CONTACT US</h3>
        <ul>
          <li>Lorem ipsum dolor sit amet.</li>
          <li>Necessitatibus, magni. Velit, nobis! Asperiores.</li>
          <li>Consequuntur odit odio alias sint.</li>
          <li>Veniam in dignissimos maxime sed.</li>
          <li>Nemo minima obcaecati fuga reprehenderit.</li>
        </ul>
      </div>
      <div>
        <h3>PARTNER WITH US</h3>
        <ul>
          <li>Lorem ipsum dolor sit amet.</li>
          <li>Necessitatibus, magni. Velit, nobis! Asperiores.</li>
          <li>Consequuntur odit odio alias sint.</li>
          <li>Veniam in dignissimos maxime sed.</li>
          <li>Nemo minima obcaecati fuga reprehenderit.</li>
        </ul>
      </div>
    </div>
    <div class="bottom">
      <p>
        Refund / Reschedule |Privacy Policy © 2013- 2022, Delight Learning
        Services and Consultancy.<br />
        All Rights Reserved. The certification names are the trademarks of their
        respective owners. Disclaimer
      </p>
    </div>
  </footer>
</template>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap");

footer {
  margin-top: 8rem;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2f2e41;
  color: white;
  padding: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Inter";
  font-weight: 100;
}
.top {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.top div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
}
.top div h3 {
  margin-bottom: 1rem;
}
.top div ul {
  list-style-type: none;
}
.top div ul li {
  margin-block: 1rem;
}
.bottom {
  width: 80%;
  margin-top: 3rem;
}

@media screen and (max-width: 830px) {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .bottom {
    width: 90vw;
  }
}
</style>
